.container{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6;
}

.title {
  font-size: .36rem;
  margin: .3rem .5rem;
}

.textarea {
  margin: 0 .3rem;
  border: none;
  min-height: 2.9rem;
  padding: .2rem;
  font-size: .28rem;
  box-shadow: 0 .04rem .06rem 0 rgba(0, 0, 0, 0.06);
}

.next-step {
  &_wrapper {
    position: absolute;
    bottom: .72rem;
    width: 100%;
    display: flex;
  }

  border: none;
  flex: 1;
  height: .84rem;
  margin: 0 .3rem;
  border-radius: .08rem;
  color: #FFF;
  font-size: .32rem;
  background: linear-gradient(90deg, #2FDCAF 0%, #06C392 100%);
}