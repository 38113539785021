html,
body {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #fff !important;
  overflow-x: hidden;
}
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
dl,
dt,
dd,
ul,
ol,
li,
p,
blockquote,
pre,
hr,
figure,
table,
caption,
th,
td,
form,
fieldset,
legend,
input,
button,
textarea,
menu {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
header,
footer,
section,
article,
aside,
nav,
hgroup,
address,
figure,
figcaption,
menu,
details {
  display: block;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption,
th {
  text-align: left;
  font-weight: normal;
}
html,
body,
fieldset,
img,
iframe,
abbr {
  border: 0;
}
i,
cite,
em,
var,
address,
dfn {
  font-style: normal;
}
[hidefocus],
summary {
  outline: 0;
}
li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
small {
  font-weight: normal;
  font-size: 100%;
}
sup,
sub {
  font-size: 83%;
}
pre,
code,
kbd,
samp {
  font-family: inherit;
}
q:before,
q:after {
  content: none;
}
textarea {
  overflow: auto;
  resize: none;
}
label,
summary {
  cursor: default;
}
a,
button {
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6,
em,
strong,
b {
  font-weight: bold;
}
del,
ins,
u,
s,
a,
a:hover {
  text-decoration: none;
}
body,
textarea,
input,
button,
select,
keygen,
legend {
  font: 0.24rem/1.14;
  color: #333;
  outline: 0;
}
body {
  background: #fff;
}
a,
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  color: #233846;
}
body {
  margin: 0;
  padding: 0;
  font-family: "PingFang-SC-Regular";
  font-size: 0.26em;
}
html,
body {
  height: 100%;
}
.yxb_h5 {
  padding-top: 0.88rem;
}
.am-picker-popup {
  font-size: 0.28rem;
}
.am-picker-popup .am-picker-popup-header {
  font-size: 0.28rem;
}
.am-picker-popup .am-picker-popup-header .am-picker-popup-item {
  font-size: 0.28rem;
}
/* ::-webkit-input-placeholder{color: #E39260;} */
.single-line_text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*# sourceMappingURL=src/index.css.map */
.Header_container__2nODE {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 0.88rem;
  background: #FFF;
  z-index: 1000; }

.Header_goback__3_nTi {
  width: .14rem; }
  .Header_goback_wrapper__b9lmN {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    padding: 0 .32rem;
    display: flex;
    align-items: center; }

.Header_title__HbnCm {
  font-size: .32rem;
  line-height: 0.88rem;
  text-align: center; }

.List_container__EuU0S {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6; }

.List_content__luz0f {
  flex: 1 1; }

.List_list-item__7FccS {
  margin: .3rem; }
  .List_list-item_card__2U3NP {
    box-shadow: 0 0.05rem 0.06rem 0 rgba(0, 0, 0, 0.06);
    border-radius: .08rem;
    background-color: #FFF; }
  .List_list-item_title__3x--R, .List_list-item_agent-send__10NT2 {
    font-size: .24rem;
    line-height: .62rem; }
  .List_list-item_title__3x--R {
    color: #999;
    padding: 0 .2rem; }
  .List_list-item_agent-send__10NT2 {
    color: #666;
    text-align: center; }
  .List_list-item_content__5N83Q {
    border-top: 1px solid #E7E7E7;
    border-bottom: 1px solid #E7E7E7; }
  .List_list-item_time__3o0iL {
    font-size: .24rem;
    color: #FFF;
    background-color: #BCBCBC;
    border-radius: .04rem;
    padding: 0 .1rem;
    line-height: .38rem;
    display: inline-block; }
    .List_list-item_time-wrapper__2uiqQ {
      text-align: center;
      margin-bottom: .33rem; }
  .List_list-item_text__2azi6 {
    font-size: .28rem;
    color: #333;
    padding: .1rem .2rem;
    line-height: .48rem; }
  .List_list-item_image__3GCKA {
    width: 2rem;
    height: 2rem; }
    .List_list-item_image-wrapper__3Dlc0 {
      padding: .2rem 0;
      display: flex;
      justify-content: center; }

.List_product__19OtN {
  padding: .32rem .24rem;
  display: flex; }
  .List_product_image__3_-zm {
    width: 2.12rem;
    height: 2.12rem; }
  .List_product_info__Dib-L {
    flex: 1 1;
    margin-left: .24rem;
    overflow: hidden; }
  .List_product_name__peVfT {
    font-size: .4rem;
    line-height: .56rem; }
  .List_product_factory__2Bptg {
    font-size: .24rem;
    color: #AFAFB0;
    line-height: .34rem; }
  .List_product_advantage__3sqpH {
    font-size: .28rem;
    line-height: .4rem;
    margin: .06rem 0; }
  .List_product_tag__1M75E {
    font-size: .24rem;
    color: #797979;
    padding: 0 .04rem;
    line-height: .34rem;
    border-radius: .08rem;
    background-color: rgba(175, 175, 175, 0.15); }
    .List_product_tag__1M75E + .List_product_tag__1M75E {
      margin-left: .08rem; }
  .List_product_certifications__3CiL9 {
    margin-top: .18rem; }

.List_personal__dagj9 {
  padding: .2rem .2rem .2rem .16rem; }
  .List_personal_info__Aawc1 {
    margin-top: .34rem;
    display: flex; }
    .List_personal_info_content__14PfN {
      margin-left: .2rem;
      flex: 1 1;
      overflow: hidden; }
  .List_personal_img__2DJsW {
    width: 0.9rem;
    height: 0.9rem; }
  .List_personal_company__3_Mwj {
    display: flex;
    align-items: center; }
    .List_personal_company_name__2JDYC {
      font-size: .32rem;
      line-height: .52rem; }
    .List_personal_company_view__1ZWT6 {
      margin-left: auto;
      flex-shrink: 0;
      font-size: .28rem;
      color: #999;
      display: flex;
      align-items: center; }
      .List_personal_company_view__1ZWT6 > img {
        width: .3rem;
        height: .3rem; }
  .List_personal_product_title__3FcNj, .List_personal_product_number__1ctg3 {
    line-height: .4rem; }
  .List_personal_product_title__3FcNj {
    font-size: .28rem;
    color: #999; }
  .List_personal_product_number__1ctg3 {
    font-size: .32rem;
    font-weight: bold;
    margin-left: .06rem; }

.List_certification__1LzpI {
  display: inline-flex;
  align-items: center;
  padding: 0 .1rem;
  border-radius: .18rem; }
  .List_certification__1LzpI.List_green__19Sqw {
    background-color: #E3F8F3;
    color: #59C1A6; }
  .List_certification__1LzpI.List_yellow__1j7c1 {
    background-color: rgba(252, 125, 26, 0.21);
    color: #F5781D; }
  .List_certification__1LzpI.List_blue__3CPYU {
    background-color: #CEF2FF;
    color: #29B4E6; }
  .List_certification__1LzpI + .List_certification__1LzpI {
    margin-left: .1rem; }
  .List_certification-title__32paN {
    font-size: .24rem;
    line-height: .36rem;
    margin-left: .06rem; }
  .List_certification-icon__3w6c8 {
    width: .24rem;
    height: .28rem; }

.List_create-group-message__1ETl_ {
  width: 100%;
  border: none;
  height: .84rem;
  border-radius: .08rem;
  color: #FFF;
  font-size: .32rem;
  background: -webkit-linear-gradient(left, #2FDCAF 0%, #06C392 100%);
  background: linear-gradient(90deg, #2FDCAF 0%, #06C392 100%); }
  .List_create-group-message_wrapper__UDv6m {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    padding: .4rem .3rem .72rem;
    background-color: inherit; }

.List_modal__4o9LT {
  top: 50%;
  position: absolute;
  width: 100%; }
  .List_modal_container__xHOqm {
    margin: 0 1.35rem;
    background-color: #FFF;
    border-radius: .2rem; }
    .List_modal_container-wrapper__3NlOH {
      position: absolute;
      width: 100%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
  .List_modal_banner__1wudr {
    width: 4.8rem;
    height: 2.3rem;
    vertical-align: bottom; }
  .List_modal_content__24sxm {
    padding: .3rem .64rem; }
  .List_modal_title__3gj5g {
    font-size: .28rem;
    text-align: center; }
  .List_modal_btns__2Asju {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .List_modal_btn__2kEhQ {
    width: 1.6rem;
    line-height: .5rem;
    border-radius: .27rem;
    border: .02rem solid #999;
    text-align: center;
    margin-top: .3rem;
    color: #999;
    background-color: transparent; }

.List_banner__1iZAF {
  width: 6.9rem;
  display: block; }

.Modal_mask__26PQI {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.4); }

.Modal_modal__hCKKb, .Modal_confirm__1R-YB {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.Modal_confirm__1R-YB {
  min-height: 4.45rem;
  background-color: #FFF;
  width: 6rem;
  border-radius: .16rem;
  margin: 0 auto;
  margin: 0 0.75rem;
  padding: 0 .4rem .4rem .3rem;
  display: flex;
  flex-direction: column; }
  .Modal_confirm_title__2mZGx {
    font-size: 0.32rem;
    font-weight: normal;
    text-align: center;
    line-height: 0.45rem;
    margin: .32rem 0;
    color: #333; }
  .Modal_confirm_content__2ovCW {
    font-size: .28rem;
    color: #666;
    line-height: .4rem;
    flex: 1 1; }
  .Modal_confirm_btn__1AJF1, .Modal_confirm_cancel__2gRGe, .Modal_confirm_determine__1qZo3 {
    flex: 1 1;
    height: .8rem;
    border-radius: .4rem;
    border: none;
    font-size: .32rem; }
    .Modal_confirm_btns__3sOTc {
      display: flex;
      flex-direction: row;
      margin-top: .3rem; }
    .Modal_confirm_btn__1AJF1:nth-child(n+2), .Modal_confirm_cancel__2gRGe:nth-child(n+2), .Modal_confirm_determine__1qZo3:nth-child(n+2) {
      margin-left: .3rem; }
  .Modal_confirm_cancel__2gRGe {
    border: 0.01rem solid #999;
    box-sizing: border-box;
    color: #999;
    background-color: transparent; }
  .Modal_confirm_determine__1qZo3 {
    background-color: #00BD8B;
    color: #FFF; }

.SendWithTarget_container__2skF- {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6; }

.SendWithTarget_header__2BE0o {
  position: relative; }

.SendWithTarget_content__6_LDH {
  flex: 1 1;
  overflow-y: scroll; }

.SendWithTarget_active-users__wm-Sk, .SendWithTarget_agent-areas__3beQX, .SendWithTarget_follow-types__2zQn8 {
  background-color: #FFF;
  box-shadow: 0 0.04rem 0.06rem 0 rgba(0, 0, 0, 0.06); }

.SendWithTarget_tabs__1Y9vM {
  display: flex;
  background-color: #FFF; }

.SendWithTarget_tab__10K9k {
  width: 1.97rem;
  line-height: .52rem;
  font-size: .32rem;
  margin: 0 auto;
  border-radius: .26rem;
  text-align: center; }
  .SendWithTarget_tab_wrapper__3tVOj {
    flex: 1 1;
    margin: .2rem 0; }
  .SendWithTarget_tab_active__1qqjJ {
    background-color: #37D0A8;
    color: #FFF; }

.SendWithTarget_active-users__wm-Sk {
  padding: .1rem .37rem .1rem .5rem; }

.SendWithTarget_active-user__3KQVF {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .2rem 0; }
  .SendWithTarget_active-user_title__2Xlwn {
    font-size: .28rem;
    color: #333; }
    .SendWithTarget_active-user_title-active__3CwsH {
      color: #20D2A4; }
  .SendWithTarget_active-user_selected__1dsWt {
    width: .24rem;
    height: .17rem; }

.SendWithTarget_agent-areas__3beQX {
  padding: .15rem .1rem;
  min-height: 5rem; }

.SendWithTarget_agent-area__3Nlui {
  display: inline-block;
  margin-left: .15rem;
  line-height: .64rem;
  width: 2.2rem;
  background-color: #F9FAFB;
  border-radius: .04rem;
  text-align: center;
  font-size: .28rem;
  margin-bottom: .2rem; }
  .SendWithTarget_agent-area_active__1GsfH {
    background-color: #20D2A4;
    color: #FFF; }

.SendWithTarget_follow-types__2zQn8 {
  height: 100%;
  display: flex; }

.SendWithTarget_follow-type__1JN53 {
  padding: .4rem .2rem .16rem; }
  .SendWithTarget_follow-type_standards__tHwoQ {
    background-color: #F9FAFB; }
    .SendWithTarget_follow-type_standards_wrapper__1Fc8i {
      width: 1.8rem;
      overflow-y: scroll; }
  .SendWithTarget_follow-type_standard__27A6s {
    line-height: 1.16rem;
    padding-left: .34rem;
    font-size: .30rem;
    color: #666; }
    .SendWithTarget_follow-type_standard_active__3Sgl1 {
      background-color: #FFF;
      color: #333;
      font-size: .32rem; }
  .SendWithTarget_follow-type_types__2fxIG {
    flex: 1 1;
    overflow-y: scroll; }
  .SendWithTarget_follow-type_title__4z_YW {
    font-size: .32rem;
    line-height: .44rem;
    color: #333; }
  .SendWithTarget_follow-type_type__qMVS9 {
    display: inline-block;
    line-height: .84rem;
    font-size: .28rem;
    min-width: 1.56rem;
    text-align: center;
    background-color: #F9FAFB;
    border-radius: .04rem;
    margin: .2rem .1rem 0;
    padding: 0 .2rem; }
    .SendWithTarget_follow-type_type_active__1KJx2 {
      line-height: .80rem;
      box-sizing: border-box;
      border: .01rem solid #00D498;
      color: #00D498;
      background-color: transparent; }

.SendWithTarget_next-step__3xxcD {
  border: none;
  height: .84rem;
  margin: .72rem .3rem;
  border-radius: .08rem;
  color: #FFF;
  font-size: .32rem;
  background: -webkit-linear-gradient(left, #2FDCAF 0%, #06C392 100%);
  background: linear-gradient(90deg, #2FDCAF 0%, #06C392 100%); }

.VipModal_modal__2e4j-, .VipModal_modal_refresh__2XxxW {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .VipModal_modal_close___tt6b {
    width: 0.46rem;
    height: 0.46rem;
    display: block;
    margin: 0 auto;
    margin-top: 0.7rem; }
  .VipModal_modal_container__3buQx {
    position: relative; }
  .VipModal_modal_content__PTHHm {
    width: 5rem;
    height: 3.77rem;
    background-size: contain;
    background-image: url(/static/media/tips_modal.9885935a.png); }
  .VipModal_modal_text__2JOQT {
    font-size: .26rem;
    color: #333;
    line-height: .48rem;
    text-align: center;
    padding-top: 1.39rem; }
    .VipModal_modal_text_vip__3RmHn {
      font-size: .28rem;
      color: #FFA42F; }
  .VipModal_modal_open_vip_btn__3zNfc {
    width: 4.08rem;
    position: absolute;
    bottom: .38rem;
    left: .46rem; }
  .VipModal_modal_refresh__2XxxW {
    width: 5rem;
    height: 3.63rem;
    background-image: url(/static/media/modal1.b339645b.png);
    background-size: contain;
    text-align: center; }
    .VipModal_modal_refresh_btn__2VGgQ {
      width: 4.08rem;
      height: 0.62rem;
      position: absolute;
      bottom: .34rem;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
    .VipModal_modal_refresh_title__Qf38A {
      font-size: .32rem;
      color: #463D3B;
      line-height: .6rem;
      margin-top: .9rem; }
    .VipModal_modal_refresh_content__2noPr {
      font-size: .26rem;
      color: #666;
      line-height: .55rem; }

#ScrollView_scrollView__LC_ra {
  height: 100vh;
  overflow-y: scroll; }

.Text_container__33P7p {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6; }

.Text_title__rO8L3 {
  font-size: .36rem;
  margin: .3rem .5rem; }

.Text_textarea__396kd {
  margin: 0 .3rem;
  border: none;
  min-height: 2.9rem;
  padding: .2rem;
  font-size: .28rem;
  box-shadow: 0 0.04rem 0.06rem 0 rgba(0, 0, 0, 0.06); }

.Text_next-step__o2_jq {
  border: none;
  flex: 1 1;
  height: .84rem;
  margin: 0 .3rem;
  border-radius: .08rem;
  color: #FFF;
  font-size: .32rem;
  background: -webkit-linear-gradient(left, #2FDCAF 0%, #06C392 100%);
  background: linear-gradient(90deg, #2FDCAF 0%, #06C392 100%); }
  .Text_next-step_wrapper__2ZVw7 {
    position: absolute;
    bottom: .72rem;
    width: 100%;
    display: flex; }

.Picture_container__3JYRo {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6; }

.Picture_title__t5voy {
  font-size: .36rem;
  margin: .3rem .67rem; }

.Picture_upload__3o13B {
  margin: 0 auto; }
  .Picture_upload__3o13B > img {
    width: 6.18rem;
    height: 3.28rem; }
  .Picture_upload__3o13B > input {
    display: none; }

.Picture_next-step__1R7Jm {
  border: none;
  flex: 1 1;
  height: .84rem;
  margin: 0 .3rem;
  border-radius: .08rem;
  color: #FFF;
  font-size: .32rem;
  background: -webkit-linear-gradient(left, #2FDCAF 0%, #06C392 100%);
  background: linear-gradient(90deg, #2FDCAF 0%, #06C392 100%); }
  .Picture_next-step_wrapper__1qF_t {
    position: absolute;
    bottom: .72rem;
    width: 100%;
    display: flex; }

.Product_container__cy8DX {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6; }

.Product_content__3O8hF {
  flex: 1 1; }

.Product_title__70eM5 {
  font-size: .36rem;
  margin: .3rem .3rem .1rem; }

.Product_products__Vl1c9 {
  margin: 0 .3rem; }

.Product_product__FSRZR {
  margin: .1rem 0;
  background-color: #FFF;
  border-radius: .08rem;
  border: .02rem solid #F1F0F0;
  display: flex;
  align-items: center;
  padding: .15rem .25rem; }
  .Product_product_item__3aQ4o {
    font-size: .32rem;
    color: #999;
    flex: 1 1; }
    .Product_product_item__3aQ4o.Product_active__3Cwdj {
      color: #20D2A4; }
  .Product_product_icon__WqFW1 {
    width: .4rem; }

.Product_next-step__1uOaX {
  border: none;
  flex: 1 1;
  height: .84rem;
  margin: 0 .3rem;
  border-radius: .08rem;
  color: #FFF;
  font-size: .32rem;
  background: -webkit-linear-gradient(left, #2FDCAF 0%, #06C392 100%);
  background: linear-gradient(90deg, #2FDCAF 0%, #06C392 100%); }
  .Product_next-step_wrapper__sh36M {
    margin-bottom: .84rem;
    width: 100%;
    display: flex; }

