.container{
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6;
}

.content {
  flex: 1;
}

.title {
  font-size: .36rem;
  margin: .3rem .3rem .1rem;
}

.products {
  margin: 0 .3rem;
}

.product {
  margin: .1rem 0;
  background-color: #FFF;
  border-radius: .08rem;
  border: .02rem solid #F1F0F0;
  display: flex;
  align-items: center;
  padding: .15rem .25rem;

  &_item {
    font-size: .32rem;
    color: #999;
    flex: 1;

    &.active {
      color: #20D2A4;
    }
  }

  &_icon {
    width: .4rem;
  }
}

.next-step {
  &_wrapper {
    margin-bottom: .84rem;
    width: 100%;
    display: flex;
  }

  border: none;
  flex: 1;
  height: .84rem;
  margin: 0 .3rem;
  border-radius: .08rem;
  color: #FFF;
  font-size: .32rem;
  background: linear-gradient(90deg, #2FDCAF 0%, #06C392 100%);
}