$height: .88rem;

.container {
  position: sticky;
  top: 0;
  height: $height;
  background: #FFF;
  z-index: 1000;
}

.goback {
  width: .14rem;
  &_wrapper {
    // background-color: red;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    padding: 0 .32rem;
    display: flex;
    align-items: center;
  }
}

.title {
  font-size: .32rem;
  line-height: $height;
  text-align: center;
}

