.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  background-color: rgba(0, 0, 0, .4);
}

.modal {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.confirm {
  @extend .modal;
  $width: 6rem;
  min-height: 4.45rem;
  background-color: #FFF;
  width: $width;
  border-radius: .16rem;
  margin: 0 auto;
  margin: 0 (7.5rem - $width) / 2;
  padding: 0 .4rem .4rem .3rem;
  display: flex;
  flex-direction: column;

  &_title {
    font-size: 0.32rem;
    font-weight: normal;
    text-align: center;
    line-height: 0.45rem;
    margin: .32rem 0;
    color: #333
  }

  &_content {
    font-size: .28rem;
    color: #666;
    line-height: .4rem;
    flex: 1;
  }

  &_btn {
    &s {
      display: flex;
      flex-direction: row;
      margin-top: .3rem;
    }

    flex: 1;
    height: .8rem;
    border-radius: .4rem;
    border: none;
    font-size: .32rem;
    &:nth-child(n+2){
      margin-left: .3rem;
    }
  }

  &_cancel {
    @extend .confirm_btn;
    border: 0.01rem solid #999;
    box-sizing: border-box;
    color: #999;
    background-color: transparent;
  }

  &_determine {
    @extend .confirm_btn;
    background-color: #00BD8B;
    color: #FFF;
  }
}