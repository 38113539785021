.container{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6;
}

.title {
  font-size: .36rem;
  margin: .3rem .67rem;
}

.upload {
  margin: 0 auto;
  > img {
    width: 6.18rem;
    height: 3.28rem;
  }
  > input {
    display: none;
  }
}

.next-step {
  &_wrapper {
    position: absolute;
    bottom: .72rem;
    width: 100%;
    display: flex;
  }

  border: none;
  flex: 1;
  height: .84rem;
  margin: 0 .3rem;
  border-radius: .08rem;
  color: #FFF;
  font-size: .32rem;
  background: linear-gradient(90deg, #2FDCAF 0%, #06C392 100%);
}